import React from 'react'
import styled from '@emotion/styled'
import { SearchForm } from '../../search'
import {
  ContainerFixed, Grid, GridItem, Box, GRID_DEFAULT_SPACING,
} from '../../ui'
import PrimaryLogo from './PrimaryLogo'
import SecondaryNav from './SecondaryNav'
import ValueProposition from './ValueProposition'
import NavLayoutResponsive from './NavLayoutResponsive'

const StyledRoot = styled(Box)`
  border-top: 3px solid ${({ theme }) => theme.colors.primary[50]};
`

const NavContainer = styled(Box)`
  border-top: 1px solid ${(props) => props.theme.colors.secondary[100]};
  border-bottom: 4px solid ${(props) => props.theme.colors.secondary[100]};
`

const AppHeader = () => (
  <>
    <StyledRoot>
      <ContainerFixed py={3}>
        <Grid
          alignItems="center"
          justifyContent="space-between"
        >
          <GridItem
            pr={0}
            position="relative"
          >
            <PrimaryLogo />
            <Box
              display="none"
              position="absolute"
              left={[0, 'auto']}
              right={['auto', 0]}
              bottom={['auto', -10]}
            >
              <ValueProposition />
            </Box>
          </GridItem>
          <GridItem
            pl={0}
            py={[2, 0]}
          >
            <SecondaryNav />
          </GridItem>
        </Grid>
      </ContainerFixed>
    </StyledRoot>
    <NavContainer
      top="0"
      zIndex={10}
      as="header"
      bg="background.body"
      position="sticky"
    >
      <ContainerFixed>
        <Grid
          alignItems="center"
          justifyContent="space-between"
        >
          <GridItem px={[0, GRID_DEFAULT_SPACING]}>
            <NavLayoutResponsive />
          </GridItem>
          <GridItem
            display={['none', 'none', 'none', 'block']}
          >
            <SearchForm />
          </GridItem>
        </Grid>
      </ContainerFixed>
    </NavContainer>
  </>
)

export default AppHeader
