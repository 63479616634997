const colors = {
  text: '#444',
  primary: {
    50: '#73cbd9',
    100: '#1F616B',
    200: '#1B515A',
  },
  secondary: {
    50: '#eeeeee',
    100: '#e5e5e5',
    200: '#737373',
  },
  accent: {
    50: '#CB4259',
    100: '#c23049',
  },
  background: {
    body: '#fff',
    50: '#333',
    100: '#2c2c2c',
  },
}

const theme = {
  colors,
  fonts: {
    heading: 'inherit',
    body: '-apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"',
  },
  fontSizes: [12, 14, 16, 20, 24, 32, 48, 64, 96],
  space: [0, 4, 8, 16, 32, 64, 128, 256, 512],
  fontWeights: {
    normal: 400,
    semibold: 500,
    bold: 600,
    bolder: 700,
    heading: 600,
  },
  lineHeights: {
    body: 1.5,
    heading: 1.1,
  },
  link: {
    color: 'inherit',
    textDecoration: 'none',
    primary: {
      fontWeight: 'bold',
      textDecoration: 'inherit',
      color: colors.primary[100],
      '&:hover, &:visited': {
        color: colors.primary[200],
      },
    },
    secondary: {
      color: 'inherit',
      textDecoration: 'inherit',
      '&:hover': {
        color: colors.primary[100],
      },
    },
  },
  text: {
    caps: {
      textTransform: 'uppercase',
    },
    display: {
      fontFamily: 'heading',
      fontSize: [5, 6, 7],
      fontWeight: 'heading',
      lineHeight: 'heading',
    },
    heading: {
      fontFamily: 'heading',
      fontWeight: 'heading',
      lineHeight: 'heading',
    },
    muted: {
      color: 'secondary.200',
    },
    description: {
      fontSize: 1,
      fontWeight: 'bold',
      variant: 'text.muted',
    },
  },
  variants: {
    placeholder: {
      bg: 'secondary.50',
    },
  },
  zIndex: {
    high: 5,
    highest: 10,
  },
  shadows: {
    small: '0 0 12px rgba(0, 0, 0, .5)',
    large: '0px 2px 40px 0px rgba(0, 0, 0, .5)',
  },
  radii: {
    default: 4,
    circle: 99999,
  },

  buttons: {
    primary: {
      fontSize: 2,
      fontWeight: 'bold',
      bg: 'primary.100',
      color: 'background.body',
      paddingTop: [14, 10],
      paddingBottom: [14, 10],
      borderRadius: 'default',
      '&:hover': {
        backgroundColor: 'primary.50',
      },
    },
    accent: {
      variant: 'buttons.primary',
      bg: 'accent.50',
      color: 'background.body',
      '&:hover': {
        bg: 'accent.100',
      },
    },
    image: {
      variant: 'buttons.primary',
      color: 'background',
      bg: 'transparent',
      padding: 0,
      width: 'auto',
      minWidth: 44,
      fontSize: 0,
      '&:disabled': {
        opacity: 0.2,
      },
      '&:hover': {
        bg: 'rgba(0, 0, 0, .02)',
      },
      '&:disabled:hover': {
        bg: 'transparent',
      },
    },
    text: {
      px: 0,
      color: 'text',
      bg: 'transparent',
      fontWeight: 'bold',
      cursor: 'pointer',
      position: 'relative',
      display: 'flex',
      alignItems: 'center',
      textDecoration: 'inherit',

      '&:hover': {
        opacity: 0.75,
      },
    },
  },
  forms: {
    input: {
      fontSize: 12,
      border: 0,
      height: [48, 36],
      borderRadius: 0,
      borderBottom: '1px solid',
      borderColor: 'secondary.100',
      '&:focus': {
        outline: 0,
        borderColor: 'primary.50',
      },
    },
    select: {
      fontSize: 12,
      height: [48, 36],
      border: 0,
      borderRadius: 0,
      borderBottom: '1px solid',
      borderColor: 'secondary.100',
      '&:focus': {
        outline: 0,
        borderColor: 'primary.50',
      },
    },
  },
}

export default theme
