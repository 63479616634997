import React from 'react'
import styled from '@emotion/styled'
import { useStaticQuery, graphql } from 'gatsby'
import { Link, List, ListItem } from '../../ui'

const StyledLink = styled(Link)`
  min-width: 48px;
  display: block;
  text-align: center;
  padding: 4px 8px;
  background-color: rgba(255, 255, 255, .9);

  &:hover {
    color: ${({ theme }) => theme.colors.background.body};
    background-color: ${({ theme }) => theme.colors.primary[50]};
  }
`

const TagCloud = () => {
  const data = useStaticQuery(graphql`
    {
      wp {
        tags(
          first: 100
          where: {
            hideEmpty: true
            language: DEFAULT
          }
        ) {
          nodes {
            name
            uri
            count
          }
        }
      }
    }
  `)

  const tags = data.wp.tags.nodes
    .filter(({ count }) => (
      count >= process.env.TERM_POST_COUNT_MINIMUM
    ))

  return (
    <List
      mx={-1}
      flexWrap="wrap"
      flexDirection="row"
      color="background.50"
    >
      {tags
        .map(({ uri, name }, i) => (
          <ListItem
            mx={1}
            my={2}
            key={`${uri}-${String(i)}`}
          >
            <StyledLink to={uri}>
              {name}
            </StyledLink>
          </ListItem>
        ))}
    </List>
  )
}

export default TagCloud
