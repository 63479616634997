import React from 'react'
import Cookies from 'universal-cookie'
import addYears from 'date-fns/addYears'
import styled from '@emotion/styled'
import {
  ContainerFixed, Flex, Box, Text, Button, Link,
} from '../ui'

const StyledLink = styled(Link)`
  text-decoration: underline;
  font-weight: ${({ theme }) => theme.fontWeights.heading};
`

// TODO too many html elements
const CookieConsentFloating = ({ cookieName = 'tiszato-gdpr-google-analytics' }) => {
  const cookies = new Cookies()
  const [isDisplayed, setIsDisplayed] = React.useState(false)

  React.useEffect(() => {
    setTimeout(() => {
      if (cookies.get(cookieName) === undefined) {
        setIsDisplayed(true)
      }
    }, 2000)
  }, [])

  const accept = React.useCallback(() => {
    setIsDisplayed(false)
    cookies.set(cookieName, true, {
      expires: addYears(new Date(), 1),
    })
  }, [])

  const refuse = React.useCallback(() => {
    setIsDisplayed(false)
    cookies.set(cookieName, false, {
      expires: addYears(new Date(), 1),
    })
  }, [])

  if (!isDisplayed) {
    return null
  }

  return (
    <Box
      as="aside"
      p={[0, 3]}
      sx={{
        zIndex: 1,
        bottom: 0,
        position: 'fixed',
        width: ['100%', '95%', 700],
        right: 0,
        left: [0, 'auto'],
      }}
    >
      <Box
        py={[2, 2, 3]}
        px={[0, 0, 2]}
        color="background.body"
        boxShadow="large"
        bg="background.100"
      >
        <ContainerFixed>
          <Flex
            alignItems="center"
            justifyContent="space-between"
            flexDirection={['column', 'row']}
          >
            <Box
              py={2}
              flex={1}
            >
              <Text>
                A weboldalon a minőségi felhasználói élmény érdekében sütiket használunk.
                {' '}
                <StyledLink
                  variant="secondary"
                  to="/adatvedelmi-iranyelvek"
                >
                  Miért?
                </StyledLink>
              </Text>
            </Box>
            <Flex
              py={2}
              flexDirection="row"
            >
              <Button
                variant="primary"
                onClick={accept}
              >
                Elfogadom
              </Button>
              <Button
                ml={3}
                variant="text"
                color="background.body"
                onClick={refuse}
              >
                Nem fogadom el
              </Button>
            </Flex>
          </Flex>
        </ContainerFixed>
      </Box>
    </Box>
  )
}

export default CookieConsentFloating
