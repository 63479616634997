import galite from 'ga-lite'
import Cookies from 'universal-cookie'
import AppWrapper from './src/AppWrapper'

const cookies = new Cookies()
const googleAnalyticsCookieName = 'tiszato-gdpr-google-analytics'
let isTrackingInitialized = false

const isTrackingEnabled = () => process.env.GA_TRACKING_ID && cookies.get(googleAnalyticsCookieName) === 'true'

const initializeTracking = () => {
  if (!isTrackingInitialized) {
    galite('create', process.env.GA_TRACKING_ID, 'auto')
    galite('set', 'anonymizeIp', true)

    isTrackingInitialized = true
  }
}

export const onRouteUpdate = () => {
  setTimeout(() => {
    if (isTrackingEnabled()) {
      initializeTracking()
      galite('send', 'pageview')
    }
  }, 2500)
}

export const wrapRootElement = AppWrapper
