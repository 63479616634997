import React from 'react'
import styled from '@emotion/styled'
import {
  Flex, Box, List, ListItem, Link, LinkExternalSafe, SrOnly,
} from '../../ui'
import { DownloadEventsButton } from '../../events'
import useSiteTitle from '../../../hooks/useSiteTitle'
import useSiteMetadata from '../../../hooks/useSiteMetadata'
import useSocialUrls from '../../../hooks/useSocialUrls'
import instagramIcon from '../../../data/images/icons/icon-instagram-dark.svg'
import youtubeIcon from '../../../data/images/icons/icon-youtube-dark.svg'
import facebookIcon from '../../../data/images/icons/icon-facebook-dark.svg'
import messengerIcon from '../../../data/images/icons/icon-messenger-dark.svg'
import podcastIcon from '../../../data/images/icons/icon-podcast-dark.svg'
import magnifierIcon from '../../../data/images/icons/icon-magnifier-dark.png'

const Img = styled('img')`
  width: 24px;
  height: auto;
  opacity: .7;

  &:hover {
    opacity: 1;
  }
`

const horizontalSpacing = 1

const SecondaryNav = () => {
  const title = useSiteTitle()
  const { messengerUrl } = useSiteMetadata()
  const { facebook, youTube, instagram } = useSocialUrls()

  return (
    <List
      alignItems="center"
      flexDirection="row"
      mx={horizontalSpacing * -1}
    >
      <ListItem
        px={horizontalSpacing}
      >
        <DownloadEventsButton />
      </ListItem>
      <ListItem
        px={horizontalSpacing}
        display={['block', 'block', 'block', 'none']}
      >
        <Link
          to="/kereso"
          title="Keress a Tisza-tónál..."
        >
          <Img
            alt=""
            width={24}
            height={24}
            src={magnifierIcon}
          />
        </Link>
      </ListItem>
      <ListItem px={horizontalSpacing}>
        <LinkExternalSafe
          href={instagram.url}
          title={`${title} az Instagramon`}
        >
          <Img
            alt=""
            width={24}
            height={24}
            src={instagramIcon}
          />
        </LinkExternalSafe>
      </ListItem>
      <ListItem px={horizontalSpacing}>
        <LinkExternalSafe
          href={youTube.url}
          title={`${title} a Youtube-on`}
        >
          <Img
            alt=""
            width={24}
            height={24}
            src={youtubeIcon}
          />
        </LinkExternalSafe>
      </ListItem>
      <ListItem px={horizontalSpacing}>
        <LinkExternalSafe
          href={facebook.url}
          title={`${title} a Facebookon`}
        >
          <Img
            alt=""
            width={24}
            height={24}
            src={facebookIcon}
          />
        </LinkExternalSafe>
      </ListItem>
      <ListItem px={horizontalSpacing}>
        <LinkExternalSafe
          href={messengerUrl}
          title={`${title} a Messengeren`}
        >
          <Img
            alt=""
            width={24}
            height={24}
            src={messengerIcon}
          />
        </LinkExternalSafe>
      </ListItem>
      <ListItem px={horizontalSpacing}>
        <Flex
          to="/podcast"
          as={Link}
          alignItems="center"
          title={`${title} Podcast`}
        >
          <Img
            alt=""
            width={24}
            height={24}
            src={podcastIcon}
          />
          <SrOnly>
            <Box
              ml={2}
              as="span"
            >
              Podcast
            </Box>
          </SrOnly>
        </Flex>
      </ListItem>
    </List>
  )
}

export default SecondaryNav
