import React, { memo } from 'react'
import { ContainerFixed } from '../ui'
import { AppFooter } from './footer'
import { AppHeader } from './header'
import CookieConsentFloating from './CookieConsentFloating'
import { MetaTagsDefault } from '../seo'
import { CalendarContextProvider } from '../events'
import { SearchPreviewContextProvider } from '../search'

const AppLayout = ({ children }) => (
  <SearchPreviewContextProvider>
    <CalendarContextProvider>
      <MetaTagsDefault />

      <AppHeader />
      <ContainerFixed
        my={4}
        as="main"
      >
        {children}
      </ContainerFixed>
      <AppFooter />

      <CookieConsentFloating />
    </CalendarContextProvider>
  </SearchPreviewContextProvider>
)

export default memo(AppLayout)
