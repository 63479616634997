import React, { useState, useCallback, useEffect } from 'react'
import galite from 'ga-lite'
import { navigate } from 'gatsby'
import styled from '@emotion/styled'
import { orderByRelevance } from './util'
import useSearchPreview from './useSearchPreview'
import {
  Box, Text, DropdownContent,
} from '../ui'
import SearchResultsCompact from './SearchResultsCompact'
import magnifierIcon from '../../data/images/icons/icon-magnifier-dark.png'

const formZIndex = 200

const StyledForm = styled('form')`
  white-space: nowrap;
  position: relative;
  z-index: ${formZIndex};
`

const StyledInput = styled(Box)`
  padding: 15px;
  padding-left: ${Math.ceil(24 * 1.5)}px;
  border: 0;
  outline: 0;
  -webkit-appearance: none;

  ~ label {
    cursor: text;
    position: absolute;
    opacity: .75;
    color: ${(props) => props.theme.colors.text};
    left: ${Math.ceil(24 * 1.5)}px;
    right: 15px;
    top: 0;
    bottom: 0;
    display: flex;
    align-items: center;
  }

  ~ button {
    opacity: .75;
  }

  &:focus, &:valid {
    border-radius: 0;
  }

  &:focus, &:valid {
    background-color: transparent;

    ~ button {
      opacity: 1;
    }
  }

  &:valid {
    ~ button {
      cursor: pointer;
      pointer-events: all;
    }

    ~ label {
      display: none;
    }
  }
`

const StyledButton = styled('button')`
  width: 20px;
  height: 20px;
  background-image: url(${magnifierIcon});
  background-size: 20px;
  background-repeat: no-repeat;
  background-position: center;
  background-color: transparent;
  -webkit-appearance: none;
  border: 0;
  outline: 0;
  position: absolute;
  left: 8px;
  top: 0;
  bottom: 0;
  margin: auto;
  pointer-events: none;
`

const SearchResultsContainer = styled(DropdownContent)`
  z-index: ${formZIndex + 1};
  top: 44px;
  right: 0;
  position: absolute;
  transition: opacity .2s ease-in;
  min-height: 50px;
  max-height: 420px;
  overflow-y: auto;
  -webkit-overflow-scroll: touch;
`

const Overlay = styled(Box)`
  z-index: ${formZIndex - 1};
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  opacity: 0;
`

const SearchForm = () => {
  const [isPreviewEnabled, setIsPreviewEnabled] = useState(false)
  const {
    searchValue, setSearchValue, searchResults, ...searchState
  } = useSearchPreview()

  const enablePreview = useCallback(() => {
    setIsPreviewEnabled(true)
  }, [])

  const disablePreview = useCallback(() => {
    setIsPreviewEnabled(false)
  }, [])

  const handleSubmit = useCallback((e) => {
    e.preventDefault()
    disablePreview()
    navigate(`/kereso/?q=${e.target.children[0].value}`, {
      replace: !!searchValue,
    })
  }, [searchValue])

  const handleChange = useCallback((newValue) => {
    setSearchValue(newValue)
  }, [setSearchValue])

  useEffect(() => {
    if (searchValue) {
      enablePreview()
    }
  }, [searchValue])

  useEffect(() => {
    if (searchState.loading) {
      galite('send', 'event', 'Search', 'type', searchValue)
    }
  }, [searchState.loading, searchValue])

  return (
    <Box position="relative">
      <StyledForm
        method="get"
        autoComplete="off"
        action="https://www.google.com/search"
        onSubmit={(e) => {
          e.persist()
          handleSubmit(e)
        }}
      >
        <StyledInput
          as="input"
          id="search"
          name="q"
          type="text"
          autoComplete="off"
          required
          pattern="\S+.*"
          value={searchValue}
          onFocus={enablePreview}
          onChange={(e) => handleChange(e.target.value)}
          fontSize={2}
          height={[48, 48, 36]}
          minWidth="initial"
          maxWidth={[200, 250, 180, 250]}
        />
        <Text
          as="label"
          htmlFor="search"
          fontSize={[2, 2, 1, 2]}
        >
          Keress a Tisza-tónál...
        </Text>
        <StyledButton
          title="Keres"
          type="submit"
        />
        <input
          type="hidden"
          name="sitesearch"
          value={process.env.SITE_BASE_URL}
        />
      </StyledForm>

      {
        (!!searchValue && isPreviewEnabled) && (
          <>
            <SearchResultsContainer
              py={2}
              width={['100%', 420]}
            >
              <SearchResultsCompact
                {...searchState}
                searchResults={(
                  searchResults
                    ? orderByRelevance(searchResults, searchValue)
                    : null
                )}
                onClick={disablePreview}
              />
            </SearchResultsContainer>
            <Overlay onClick={disablePreview} />
          </>
        )
      }
    </Box>
  )
}

export default SearchForm
