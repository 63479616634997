import React from 'react'
import {
  ContainerFixed, Grid, GridItem, Box, Heading,
} from '../../ui'
import FooterNav from './FooterNav'
import TagCloud from './TagCloud'
import SocialLinks from './SocialLinks'
import CompanyLinks from './CompanyLinks'
import PartnerLogos from './PartnerLogos'
import SupporteeLogos from './SupporteeLogos'
import UnderlinedHeading from './UnderlinedHeading'
import useSiteTitle from '../../../hooks/useSiteTitle'

const topColumWidth = [1, 1 / 2, 1 / 2, 1 / 4]

const AppFooter = () => {
  const siteTitle = useSiteTitle()

  return (
    <Box
      as="footer"
      bg="background.50"
      color="background.body"
    >
      <ContainerFixed py={3}>
        <Grid>
          <GridItem
            py={3}
            as="section"
            width={topColumWidth}
          >
            <UnderlinedHeading
              mb={3}
              as="header"
            >
              Itt is fent vagyunk
            </UnderlinedHeading>
            <SocialLinks />
          </GridItem>
          <GridItem
            py={3}
            as="section"
            width={topColumWidth}
          >
            <UnderlinedHeading
              mb={3}
              as="header"
            >
              {siteTitle}
            </UnderlinedHeading>
            <FooterNav />
          </GridItem>
          <GridItem
            py={3}
            as="section"
            width={topColumWidth}
          >
            <UnderlinedHeading
              mb={3}
              as="header"
            >
              Digitális tudásunk
            </UnderlinedHeading>
            <CompanyLinks />
          </GridItem>
          <GridItem
            py={3}
            as="section"
            width={topColumWidth}
          >
            <UnderlinedHeading
              mb={3}
              as="header"
            >
              Őket támogatjuk
            </UnderlinedHeading>
            <SupporteeLogos />
          </GridItem>
          <GridItem
            py={3}
            as="section"
            width={1}
          >
            <UnderlinedHeading
              mb={3}
              as="header"
            >
              Partnereink
            </UnderlinedHeading>
            <PartnerLogos />
          </GridItem>
          <GridItem
            py={3}
            as="section"
            width={1}
          >
            <UnderlinedHeading
              mb={3}
              as="header"
            >
              Címkék
            </UnderlinedHeading>
            <TagCloud />
          </GridItem>
        </Grid>
      </ContainerFixed>

      <Box
        py={3}
        as="section"
        bg="background.100"
      >
        <ContainerFixed>
          <Heading
            as="header"
            fontSize={2}
          >
            {siteTitle}
          </Heading>
        </ContainerFixed>
      </Box>
    </Box>
  )
}

export default AppFooter
