import React from 'react'
import styled from '@emotion/styled'
import { List, ListItem, Link } from '../../ui'

const ScrollContainer = styled(List)`
  overflow: hidden;
  overflow-x: auto;
  white-space: nowrap;
  text-transform: uppercase;

  &::-webkit-scrollbar {
    width: 0 !important;
    display: none;
  }
`

const NavLink = styled(Link)`
  outline: 0;
  display: inline-block;
  padding: ${(props) => props.theme.space[2]}px 0;

  &:hover, &.active {
    box-shadow: 0 3px 0 ${(props) => props.theme.colors.primary[50]};
  }
`

const PrimaryNav = ({ pages }) => (
  <ScrollContainer
    py={1}
    ml={[0, -2]}
    fontSize={2}
    fontWeight="bolder"
    flexWrap="nowrap"
    flexDirection="row"
  >
    {
      pages.map(({ path, label }, i) => (
        <ListItem
          px={['6px', 2]}
          flex="0 0 auto"
          key={String(path + i)}
        >
          <NavLink
            to={path}
            partiallyActive
            activeClassName="active"
          >
            {label}
          </NavLink>
        </ListItem>
      ))
    }
  </ScrollContainer>
)

export default PrimaryNav
